import { TorchIcon, WaterIcon } from "../../images";
import FeatureImage from "./feature.svg";

export default function BrandPanel() {
  return (
    <div className="relative">
      <div
        className="hidden absolute top-0 inset-x-0 h-1/2 bg-pale-green lg:block"
        aria-hidden="true"
      />
      <div className="max-w-7xl mx-auto bg-[#D7F0F3] lg:bg-pale-green lg:px-8">
        <div className="lg:grid lg:grid-cols-12">
          <div className="relative z-10 lg:col-start-1 lg:row-start-1 lg:col-span-4 lg:py-16 lg:bg-transparent">
            <div
              className="absolute inset-x-0 h-1/2 bg-grey-wash lg:hidden"
              aria-hidden="true"
            />
            <div className="max-w-md mx-auto px-4 sm:max-w-3xl sm:px-6 lg:max-w-none lg:p-0">
              <div className="aspect-w-10 aspect-h-6 sm:aspect-w-2 sm:aspect-h-1 lg:aspect-w-1">
                <img
                  className="object-contain object-center rounded-3xl shadow-2xl bg-white p-5"
                  src={FeatureImage}
                  alt=""
                  aria-hidden="true"
                />
              </div>
            </div>
          </div>

          <div className="relative bg-[#D7F0F3] lg:col-start-3 lg:row-start-1 lg:col-span-10 lg:rounded-3xl lg:grid lg:grid-cols-10 lg:items-center">
            <div className="relative max-w-md mx-auto py-12 px-4 space-y-6 sm:max-w-3xl sm:py-16 sm:px-6 lg:max-w-none lg:p-0 lg:col-start-4 lg:col-span-6">
              <div className="flex space-x-4">
                <img src={WaterIcon} aria-hidden="true" alt="" />
                <img src={TorchIcon} aria-hidden="true" alt="" />
              </div>
              <h2
                className="text-3xl font-extrabold text-dark"
                id="join-heading"
              >
                We’re starting with water &amp; light
              </h2>
              <p className="text-lg text-dark">
                Kit Me helps you find out how much water and bleach (for water
                treatment) you need for the size of your household, while also
                helping you figure out how many torches and batteries you need.
                But that’s just the start…
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
