import { CheckIcon } from "@heroicons/react/outline";
import { User } from "firebase/auth";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useUserData } from "../../firebase/useUserData";
import { GoalDays } from "../../types/fireward";
import { calculateGoals, humaniseDays } from "../../utils";

export default function GoalCards({ user }: { user: User }) {
  const navigate = useNavigate();
  const { userData, loading, error, updateHousehold } = useUserData(user.uid);

  const goals = useMemo(
    () =>
      userData?.household.size
        ? calculateGoals(userData?.household.size)
        : null,
    [userData?.household.size]
  );

  if (loading) return <></>;
  if (error || !userData) return <p>Error retrieving data.</p>;
  if (!goals) return <></>;

  async function setGoal(goalDays: GoalDays) {
    await updateHousehold({ goalDays });
    navigate("/dashboard");
  }

  const featuresForGoalDays = (goalDays: GoalDays) => {
    return [
      `${goals[goalDays]["water"]} litres of stored water`,
      `${goals[goalDays]["bleach"]} litres of bleach`,
      `${goals[goalDays]["torch"]} ${
        goals[goalDays]["torch"] > 1 ? "Torches" : "Torch"
      }`,
      `${goals[goalDays]["battery"]} ${
        goals[goalDays]["battery"] > 1 ? "Sets" : "Set"
      } of batteries`,
    ];
  };
  return (
    <div className="max-w-7xl mx-auto">
      <div className="space-y-12 lg:space-y-0 lg:grid lg:grid-cols-3 lg:gap-x-8">
        <GoalCard
          onSelect={setGoal}
          goalDays={3}
          features={featuresForGoalDays(3)}
        >
          Let’s make sure you have your household’s basic needs covered
          immediately after an emergency.
        </GoalCard>
        <GoalCard
          onSelect={setGoal}
          goalDays={7}
          features={featuresForGoalDays(7)}
        >
          You’re prepared for immediately after an emergency, but what’s next?
          Making sure you have a week of basic supplies.
        </GoalCard>
        <GoalCard
          onSelect={setGoal}
          goalDays={14}
          features={featuresForGoalDays(14)}
        >
          Okay, you're set for a week. But what if you live further out of town,
          could have trouble accessing supplies or your local Emergency
          Management group suggest 2 weeks of supplies is preferable for your
          situation?
        </GoalCard>
      </div>
    </div>
  );
}

export type GoalCardProps = {
  goalDays: GoalDays;
  children: string;
  onSelect: (goalDays: GoalDays) => void;
  features: string[];
};

export function GoalCard({
  goalDays,
  children,
  onSelect,
  features,
}: GoalCardProps) {
  return (
    <div className="relative p-8 bg-white border border-light-grey rounded-2xl shadow-sm flex flex-col">
      <div className="flex-1">
        <h3 className="mt-4 flex items-baseline text-dark text-5xl font-extrabold tracking-tight">
          {humaniseDays(goalDays)}
        </h3>
        <p className="mt-6 text-content-grey">{children}</p>

        <ul className="mt-6 space-y-6">
          {features.map((feature) => (
            <li className="flex" key={feature}>
              <CheckIcon
                className="flex-shrink-0 w-6 h-6 text-[#469B72]"
                aria-hidden="true"
              />
              <span className="ml-3 text-content-grey">{feature}</span>
            </li>
          ))}
        </ul>
      </div>

      <button
        onClick={() => onSelect(goalDays)}
        className="mt-8 block w-full py-3 px-6 border border-transparent rounded-md text-center font-medium bg-green-100 text-white hover:bg-accent-green"
      >
        Set this as your goal
      </button>
    </div>
  );
}
