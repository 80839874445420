import { ReactNode } from "react";
import CTA from "../cta";

export default function GenericPage({
  title,
  subtitle,
  children,
}: {
  title: string;
  subtitle?: () => ReactNode;
  children?: ReactNode;
}) {
  return (
    <div className="container mx-auto pt-5 sm:pt-10 space-y-8 sm:space-y-16 pb-20">
      <CTA title={title}>{subtitle && subtitle()}</CTA>
      {children && (
        <div className="max-w-5xl mx-auto space-y-4 px-6">{children}</div>
      )}
    </div>
  );
}
