import GoalProgressCardBase, { GoalProgressCardProps } from "./base";

type BleachGoalProgressCardProps = GoalProgressCardProps & {
  measuringCupValue?: boolean;
  measuringCupChange: (value: boolean) => void;
};

export default function BleachGoalProgressCard({
  measuringCupValue,
  measuringCupChange,
  ...props
}: BleachGoalProgressCardProps) {
  return (
    <GoalProgressCardBase {...props} step={0.5}>
      <div className="flex mt-8">
        <input
          id="measuringCup"
          name="measuringCup"
          type="checkbox"
          className="focus:ring-indigo-500 h-4 w-4 mt-1 text-accent-green border-light-grey rounded focus:ring-accent-green focus:border-accent-green"
          checked={measuringCupValue}
          onChange={(e) => measuringCupChange(e.target.checked)}
        />
        <div className="ml-2">
          <label
            htmlFor="measuringCup"
            className="font-medium text-content-grey focus:ring-accent-green focus:border-accent-green"
          >
            Measuring utensil eg syringe
          </label>
          <p className="text-content-grey">
            To measure the correct ratio of water to bleach
          </p>
        </div>
      </div>
    </GoalProgressCardBase>
  );
}
