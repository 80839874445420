import { Link, useNavigate } from "react-router-dom";
import CTA from "../../components/cta";
import { humaniseDays } from "../../utils";
import GoalProgress from "../../components/goal-progress";
import { User } from "firebase/auth";
import { useEffect } from "react";
import ProfileHeader from "../../components/profile-header";
import { useUserData } from "../../firebase/useUserData";
import UsefulResources from "../../components/useful-resources";
import Tips from "../../components/tips";

export default function DashboardMain({ user }: { user: User }) {
  const navigate = useNavigate();
  const { userData, loading, error, goals, updateStored } = useUserData(
    user.uid
  );

  useEffect(() => {
    if (loading || error) return;

    if (userData?.household.goalDays === null) {
      navigate("/dashboard/goal");
    }
  }, [loading, error, userData?.household.goalDays, navigate]);

  if (loading) return <></>;
  if (error || !userData?.household) return <p>Error retrieving data.</p>;
  if (!goals || !userData?.household.goalDays) return <></>;

  return (
    <>
      <ProfileHeader user={user} />
      <div className="space-y-8 max-w-5xl mx-auto px-4 sm:px-6 sm:space-y-16">
        <CTA
          title={
            <>
              Your <span className="text-bright-green">kit progress</span>
            </>
          }
        >
          <p>
            Update the page below as you collect the items you need to build
            your kit. This will help you work towards your goal and get your
            household ready for an emergency.
          </p>
        </CTA>
        <div className="mx-auto text-center lg:px-8 space-y-4">
          <h3 className="text-xl font-extrabold text-dark sm:text-3xl">
            Update your{" "}
            <span className="text-bright-green">
              {humaniseDays(userData.household.goalDays, false)}
            </span>{" "}
            kit progress
          </h3>
          <div>
            <Link
              to="goal"
              className="text-base font-normal text-green-100 focus:underline hover:underline sm:col-span-4"
            >
              Change kit goal
            </Link>
          </div>
        </div>
        <GoalProgress
          goals={goals}
          household={userData.household}
          updateStored={updateStored}
        />
        <Tips />
        <UsefulResources />
      </div>
    </>
  );
}
