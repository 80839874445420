import { User } from "firebase/auth";
import ChangeCredentialsForm from "../../components/change-credentials-form";
import ChangeHouseholdForm from "../../components/change-household-form";

export default function DashboardDetails({ user }: { user: User }) {
  return (
    <div className="space-y-7 max-w-lg mx-auto px-6">
      <ChangeHouseholdForm user={user} />
      <ChangeCredentialsForm user={user} />
    </div>
  );
}
