import { ComponentType, ReactNode } from "react";
import classNames from "classnames";

type CentreProps = {
  title: ReactNode;
  titleSize?: 1 | 2 | 3;
  children: ReactNode;
  Link?: ComponentType<{ className: string }>;
};

const SIZE_MAP: { [key: number]: string } = {
  1: "text-4xl sm:text-6xl",
  2: "text-3xl sm:text-4xl",
  3: "text-lg sm:text-xl",
};

export default function CTA({
  title,
  titleSize = 1,
  children,
  Link,
}: CentreProps) {
  const titleClass = SIZE_MAP[titleSize];
  return (
    <div className="max-w-5xl mx-auto text-center py-8 px-4 sm:px-6 lg:px-8">
      <h2 className={classNames("font-extrabold text-dark", titleClass)}>
        {title}
      </h2>
      <div className="mt-4 text-lg leading-6 text-dark space-y-6">
        {children}
      </div>
      {Link && (
        <Link className="mt-8 inline-flex items-center justify-center py-3 px-12 sm:px-5 border border-transparent text-base font-medium rounded-md bg-accent-green text-white" />
      )}
    </div>
  );
}
