import GenericPage from "../components/generic-page";

export default function Privacy() {
  return (
    <GenericPage title="Privacy Policy">
      <p>
        We collect personal information from you, including information about
        your:
      </p>
      <ul className="ml-4 list-disc">
        <li>Name</li>
        <li>Number of people in your household</li>
        <li>Contact information (eg email address)</li>
        <li>Interaction with the website (statistical analysis)</li>
        <li>Postcode</li>
      </ul>

      <p>We collect your personal information in order to:</p>

      <ul className="ml-4 list-disc">
        <li>
          Help you get ready and stay ready for Emergencies, by helping you plan
          and manage your supplies
        </li>
        <li>
          Help Emergency Management Professionals understand and improve
          Emergency Preparedness and Resilience
        </li>
        <li>
          Produce non-personal analytics to help us understand and improve Kiwis
          resilience to emergencies
        </li>
      </ul>

      <p>
        Providing information is optional. If you choose not to enter the
        information we list, we'll be unable to provide access to Kit Me.
      </p>

      <p>
        You have the right to ask for a copy of any personal information we hold
        about you, and to ask for it to be corrected if you think it is wrong.
        If you’d like to ask for a copy of your information, or to have it
        corrected, please contact us at.
      </p>

      <p>
        We keep your information safe by storing it with service providers that
        we trust. Any personal information you provide to us may be stored on
        the secure servers of our trusted service providers, which may be
        located outside New Zealand. This may involve the transfer of your
        personal information to countries which have different legal protection
        for personal information than New Zealand.
      </p>

      <p>We respect the New Zealand Privacy Act 2020.</p>
    </GenericPage>
  );
}
