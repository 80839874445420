import { User } from "firebase/auth";
import Alert from "../../components/alert";
import CTA from "../../components/cta";
import GoalCards from "../../components/goal-cards";
import ProfileHeader from "../../components/profile-header";

export default function DashboardGoal({ user }: { user: User }) {
  return (
    <>
      <ProfileHeader user={user} />
      <CTA
        title={
          <>
            Choose a <span className="text-bright-green">readiness goal</span>{" "}
            that works for you.
          </>
        }
      >
        <p>
          You can start off with small steps or look at the big picture. Each
          option is already calculated below for the number of people in your
          household.
        </p>
        <p>You can change your choice at any time.</p>
      </CTA>
      <GoalCards user={user} />
      <Alert>
        West Coast Emergency Management Group Suggest that because of local
        risks, Coasters should be ready with a minimum of 7 days of supplies,
        however two weeks is ideal.
      </Alert>
    </>
  );
}
