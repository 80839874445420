import { Goals } from "./types";
import { GoalDays } from "./types/fireward";

/*
Very basic function to convert from a number of days to a number of weeks and days.

e.g. 2 -> "2 days", 20 -> '2 weeks 6 days'
*/
export function humaniseDays(days: number, pluralise: boolean = true) {
  if (days < 7) {
    return `${days} days`;
  } else {
    var weeks = Math.floor(days / 7);
    var rem = days % 7;
    return (
      `${weeks} week` +
      (pluralise && weeks > 1 ? "s" : "") +
      (rem !== 0 ? `${rem} day` + (pluralise && rem > 1 ? "s" : "") : "")
    );
  }
}

export function checkPasswordMeetsRequirements(password: string): boolean {
  return /\d/.test(password) && /[A-Z]/.test(password) && password.length >= 8;
}

/* All goal calculations are based on https://docs.google.com/document/d/1KXElVRIReK3CueROrKmb1vQSLnTDNIvZgxA7QGitu2M */
function calculateWaterGoalForDays(goalDays: GoalDays, householdSize: number) {
  if (goalDays === 3) {
    return goalDays * householdSize * 3;
  }
  return goalDays * householdSize * 20;
}
function calculateBleachGoalForDays(goalDays: GoalDays) {
  switch (goalDays) {
    case 3:
      return 1;
    case 7:
      return 1.5;
    case 14:
      return 2;
  }
}

function calculateTorchGoalForDays(goalDays: GoalDays, householdSize: number) {
  switch (goalDays) {
    case 3:
      return 1;
    case 7:
      return householdSize;
    case 14:
      return householdSize;
  }
}

function calculateBatteryGoalForDays(
  goalDays: GoalDays,
  householdSize: number
) {
  switch (goalDays) {
    case 3:
      return 2;
    case 7:
      return householdSize;
    case 14:
      return householdSize * 2;
  }
}

export function calculateGoalForDays(
  goalDays: GoalDays,
  householdSize: number
) {
  return {
    water: calculateWaterGoalForDays(goalDays, householdSize),
    bleach: calculateBleachGoalForDays(goalDays),
    torch: calculateTorchGoalForDays(goalDays, householdSize),
    battery: calculateBatteryGoalForDays(goalDays, householdSize),
  };
}

export function calculateGoals(householdSize: number): Goals {
  return {
    3: calculateGoalForDays(3, householdSize),
    7: calculateGoalForDays(7, householdSize),
    14: calculateGoalForDays(14, householdSize),
  };
}
