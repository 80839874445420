import { ReactNode } from "react";
import { humaniseDays } from "../../utils";

export type GoalProgressCardProps = {
  name: string;
  unit: string;
  unitPlural?: string;
  question: string;
  value: number;
  icon: string;
  target: number;
  goalDays: number;
  size: number;
  onChange: (value: number) => void;
  step?: number;
};

export default function GoalProgressCardBase({
  name,
  unit,
  unitPlural,
  question,
  value,
  icon,
  target,
  goalDays,
  size,
  onChange,
  children,
  step,
}: GoalProgressCardProps & { children?: ReactNode }) {
  const members = `${size} ${size > 1 ? "people" : "person"}`;
  return (
    <div className="relative bg-white pt-5 sm:pt-6 shadow rounded-lg overflow-hidden">
      <div className="px-4 sm:px-6">
        <img
          src={icon}
          className="absolute h-14 w-14 text-white"
          aria-hidden="true"
          alt=""
        />
        <p className="ml-16 text-sm font-medium text-content-grey truncate">
          {name} ({members})
        </p>
      </div>
      <div>
        <div className="px-4 sm:px-6 mb-6">
          <p className="ml-16 text-2xl font-semibold text-black">
            {target}
            {unitPlural && target > 1 ? unitPlural : unit}
            {unit.toLowerCase() === "l" && <> for {humaniseDays(goalDays)}</>}
          </p>
        </div>
        <div className="bg-grey-wash px-4 py-5 sm:px-6">
          <div className="space-y-4">
            <div className="space-y-2">
              <label
                htmlFor={name}
                className="block text-xs font-medium text-content-grey"
              >
                {question}
              </label>
              <input
                type="number"
                id={name}
                name={name}
                className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-light-grey rounded-md focus:ring-accent-green focus:border-accent-green"
                value={value.toString()}
                step={step}
                onChange={(e) => onChange(Number(e.target.value))}
              />
            </div>
            {children}
            <a
              href="#tips"
              tabIndex={0}
              className="text-xs font-normal text-green-100 focus:underline hover:underline sm:col-span-4 block"
            >
              View tips below
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
