import { sendEmailVerification, User } from "firebase/auth";
import { useState } from "react";
import Alert from "../alert";
import CTA from "../cta";

export default function VerifyEmail({ user }: { user: User }) {
  const [error, setError] = useState();
  const [sent, setSent] = useState(false);

  return (
    <>
      <div className="max-w-2xl mx-auto">
        {error && (
          <div className="mb-5">
            <Alert type="error">{error}</Alert>
          </div>
        )}
        {sent && (
          <div className="mb-5">
            <Alert>Verification email sent. Please check your inbox.</Alert>
          </div>
        )}
      </div>
      <CTA
        title="Verify your email"
        Link={(props) => (
          <button
            {...props}
            type="submit"
            onClick={async () => {
              try {
                setSent(false);
                await sendEmailVerification(user);
                setSent(true);
              } catch (e: any) {
                setError(e.message);
              }
            }}
          >
            Resend verification email
          </button>
        )}
      >
        <p>
          Please check your emails for a link. If you haven't received an email
          click the link below.
        </p>
      </CTA>
    </>
  );
}
