import { CheckCircleIcon } from "@heroicons/react/solid";

export type ProgressBarTheme =
  | "bg-water"
  | "bg-bleach"
  | "bg-torch"
  | "bg-battery";

export type ProgressBarProps = {
  title: string;
  fill: number;
  theme?: ProgressBarTheme;
};

export default function ProgressBar({ title, fill, theme }: ProgressBarProps) {
  const cappedFill = Math.min(fill, 100);
  return (
    <div className="space-y-1">
      <span className="text-sm uppercase">{title}</span>
      <div className="flex flex-row relative">
        <div className="flex-1 bg-light-grey h-6 rounded-full mr-8 overflow-hidden">
          <span
            className={`${theme ?? "bg-accent-green"} h-6 inline-block`}
            style={{ width: `${cappedFill}%` }}
          ></span>
        </div>
        {fill >= 100 && (
          <CheckCircleIcon className="absolute right-0 h-6 w-6 text-accent-green" />
        )}
      </div>
    </div>
  );
}
