import { BatteryIcon, BleachIcon, TorchIcon, WaterIcon } from "../../images";

export default function Features() {
  return (
    <div className="relative">
      <div className="mx-auto max-w-md px-4 text-center sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-5xl">
        <div className="mt-12">
          <div className="grid gap-y-8 gap-x-4 grid-cols-2 lg:grid-cols-4">
            <Feature icon={WaterIcon} title="Stored water" />
            <Feature icon={BleachIcon} title="Bleach" />
            <Feature icon={TorchIcon} title="Torches" />
            <Feature icon={BatteryIcon} title="Batteries" />
          </div>
        </div>
      </div>
    </div>
  );
}

function Feature({ icon, title }: { icon: string; title: string }) {
  return (
    <div className="flow-root bg-grey-wash rounded-lg px-6 pb-4 sm:pb-8 shadow-lg">
      <div className="-mt-6">
        <div>
          <img
            src={icon}
            className="h-24 w-24 text-white inline-flex items-center justify-center"
            aria-hidden="true"
            alt=""
          />
        </div>
        <h3 className="mt-8 font-extrabold text-xl text-dark tracking-tight">
          {title}
        </h3>
      </div>
    </div>
  );
}
