import { useState } from "react";

export type ValidationErrorsType<T> = { fields: (keyof T)[]; error: string }[];

export class ValidationErrors<T> {
  errors: ValidationErrorsType<T> = [];

  addError(field: (keyof T)[] | keyof T, error: string) {
    this.errors.push({
      fields: field instanceof Array ? field : [field],
      error,
    });
  }

  clearErrors(field?: keyof T) {
    if (field === undefined) {
      this.errors = [];
    } else {
      // Remove all validation errors that aren't for a given field.
      this.errors = this.errors.reduce((previous, v) => {
        if (v.fields.includes(field)) return previous;
        return [...previous, v];
      }, [] as ValidationErrorsType<T>);
    }
  }

  hasErrors(field?: keyof T) {
    if (field === undefined) {
      return this.errors.length !== 0;
    }
    return this.errors.some((v) => v.fields.includes(field));
  }

  clearAll() {
    this.errors = [];
  }
}

export default function useForm<T>({
  defaultState,
  onValidate,
  onSubmit,
  onError,
}: {
  defaultState: T;
  onValidate?: () => ValidationErrors<T>;
  onSubmit: () => Promise<void>;
  onError?: () => void;
}) {
  const [error, setError] = useState("");
  const [validating, setValidating] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const [formState, setFormState] = useState<T>(defaultState);

  const updateFormState = (state: Partial<T>) =>
    setFormState((previous) => ({ ...previous, ...state }));

  const [validationErrors, setValidationErrors] = useState<ValidationErrors<T>>(
    new ValidationErrors()
  );

  const validate = () => {
    setValidating(true);
    try {
      if (onValidate) {
        const errors = onValidate();
        setValidationErrors(errors);
        return errors;
      }
    } finally {
      setValidating(false);
    }
    return null;
  };

  const validateAndSubmit = async () => {
    setSubmitting(true);
    const errors = validate();

    if (!errors?.hasErrors()) {
      try {
        await onSubmit();
      } catch (e: any) {
        setError(e.message);
        onError && onError();
      } finally {
        setSubmitting(false);
      }
    } else {
      onError && onError();
      setSubmitting(false);
    }
  };

  return {
    error,
    disabled: validating || submitting,
    formState,
    updateFormState,
    validationErrors,
    validateAndSubmit,
  };
}
