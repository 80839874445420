import { ReactNode } from "react";
import { BulbIcon } from "../../images";

export default function Tips() {
  return (
    <div id="tips">
      <div className="flex mb-4">
        <img src={BulbIcon} className="h-14 w-14" aria-hidden="true" alt="" />
        <h2 className="text-2xl mt-3 ml-3 font-semibold text-dark">
          Useful resources
        </h2>
      </div>
      <dl className="space-y-5">
        <Tip title="Emergency Water">
          <ul className="ml-4 list-disc">
            <li>
              Store water in clean fizzy drink bottles or 10L food grade (water only) jerrycans, in
              cupboards or the freezer or, for larger amounts, an outside rainwater storage
              tank.
            </li>
            <li>
              Consider container weight. For example, a 20L container may be too
              heavy and awkward for you to use compared to two 10L containers.
            </li>
            <li>
              Don’t use plastic jugs or cardboard containers that have had milk
              or fruit juice in them. (Milk protein and fruit sugars cannot be
              removed from these containers)
            </li>
            <li>
              For the first 3 days after an event, you need at least 3L of water
              per person, per day. However, for a longer period of time 20L per
              person per day is suggested to stay healthy.{" "}
            </li>
            <li>
              Emergency water should be checked every 6 months. If the water is not clear - 
              empty, clean and refill bottles with fresh water and bleach.
            </li>
            <li>
              For more information on emergency water,{" "}
              <a
                target="_blank"
                rel="noreferrer"
                className="font-normal text-green-100 hover:underline"
                href="https://getready.govt.nz/prepared/household/supplies/storing-water/"
              >
                visit the Get Ready website
              </a>
            </li>
          </ul>
        </Tip>
        <Tip title="Bleach">
          <ul className="ml-4 list-disc">
            <li>
              Bleach containing additives or lemon scent are unsafe for water
              treatment.
            </li>
            <li>
              Watch out for ‘extra strength’ bleach - this will affect how much
              you need to add. Standard bleach is 3% Solution Hypochlorite
            </li>
            <li>
              To treat water in an emergency you need to add 0.25 mls of bleach
              per litre of water.
            </li>
            <li>
              Your bleach quantity suggestion above, includes enough to treat
              your recommended water quantity, is rounded up to a standard
              bottle size and includes extra for emergency household cleaning
              purposes too.
            </li>
            <li>
              You can purchase bleach which has water treatment instructions on
              the bottle. This makes life much easier in an emergency, while
              also giving you confidence that you are using the right type of
              bleach for water treatment.
            </li>
            <li>
              To measure bleach you will need either:{" "}
              <ul className="ml-4 list-disc">
                <li>a measuring spoon</li>
                <li>a syringe</li>
                <li>measuring cup or measuring jug</li>
              </ul>
              This depends on how much water you are treating at once. We
              suggest clearly labelling them, using them only for measuring
              bleach, and storing them out of reach of children or vulnerable
              adults. Measuring items are available at pharmacies, kitchen
              shops, and hardware stores.{" "}
            </li>
          </ul>
        </Tip>
        <Tip title="Torch">
          <ul className="ml-4 list-disc">
            <li>
              Test any torches you already have at home to make sure they are
              reliable.
            </li>
            <li>Older torches may need spare bulbs.</li>
            <li>
              Consider having more than one type of torch for flexibility in
              different situations. eg a head torch or hand torch for walking, a
              lantern type for food preparation or lighting an area.
            </li>
            <li>
              If you are purchasing a torch, aim for the most efficient torch in
              your price bracket. This will make life a lot easier in an
              extended emergency.
            </li>
            <li>
              Check the packaging or manufacturer information for how long your
              torch will last on each set of batteries and how bright it will be
              on different settings.
            </li>
          </ul>
        </Tip>
        <Tip title="Batteries">
          <ul className="ml-4 list-disc">
            <li>
              To get the most from every torch, ensure your batteries are
              suitable for high drain devices.
            </li>
            <li>
              Make sure you have the right size of battery for each torch and
              that they are clearly labelled so you know in a rush which
              batteries go in which torch (for example,‘for Bobs torch’).
            </li>
            <li>
              If in doubt test your torch at home, and if you get more than one,
              you can vary the type eg a head torch, and a lamp or spotlight
              style.
            </li>
            <li>
              If your torch is particularly bright, consider stocking more
              batteries than the minimum suggested above for your goal.
            </li>
          </ul>
        </Tip>
      </dl>
    </div>
  );
}

type TipProps = {
  title: string;
  children: ReactNode;
};

export function Tip({ title, children }: TipProps) {
  return (
    <div>
      <dt className="font-bold">{title}</dt>
      <dd className="text-content-grey space-y-2">{children}</dd>
    </div>
  );
}
