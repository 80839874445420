import GenericPage from "../components/generic-page";

export default function TermsAndConditions() {
  return (
    <GenericPage
      title="Website Terms of Use"
      subtitle={() => (
        <p>By viewing and using this site, you agree to these Terms of use.</p>
      )}
    >
      <h3 className="font-bold">1. Agreement to terms</h3>
      <p>
        Welcome to www.kitme.co.nz, a webapp owned and operated by Inclement Ltd
        (a social enterprise, based in NZ, focused on increasing Kiwis emergency
        preparedness) along with other partners including Emergency Management
        West Coast, the National Emergency Management Authority (NEMA) and The
        Media Suite Ltd.
      </p>
      <p>
        These Terms of Use are entered into between you and Inclement Limited.
        These Terms of Use, together with any documents incorporated by
        reference, govern your access to and use of the Kit Me website,
        including any contents or components of the website. By viewing and
        using the Kit Me website, you are considered to agree to these terms and
        conditions without qualification. If you do not agree to be bound by
        these terms and conditions, you must stop accessing and using our
        website.
      </p>
      <p>
        Where your access and use is on behalf of another person (e.g. a
        household member), you confirm that you are authorised to, and do in
        fact, agree to these Terms on that person’s behalf and that, by agreeing
        to these Terms on that person’s behalf, that person is bound by these
        Terms.
      </p>
      <p>
        Inclement and Kit Me are based in New Zealand. These Terms, and any
        dispute relating to these Terms or the Website, are governed by and must
        be interpreted in accordance with the laws of New Zealand.
      </p>
      <p>
        These terms apply to the entire contents and components of the site.
      </p>

      <h3 className="font-bold">2. Revisions to these Terms of use</h3>
      <p>
        Inclement Limited reserves the right to change or modify these Terms at
        any time. Any such changes or modifications take effect when we post the
        Terms to the website and shall apply to all access and use of the
        website thereafter. By continuing to access and use the website, you
        agree to be bound by the amended Terms.
      </p>

      <h3 className="font-bold">3. Disclaimer</h3>
      <p>
        Our website may include links to third party materials for your
        convenience. We will not be responsible for the content of the linked
        websites or liable for any direct or indirect loss or damage suffered by
        you from accessing, using, relying on or trading with third parties.
      </p>
      <p>
        We endeavour to ensure that all information included on the website is
        from good quality information sources. However, to the fullest extent
        permitted by law, we exclude all liability of Inclement Limited, its
        partners, or its or their affiliates, service providers, employees,
        agents, officers or directors for any loss or damage of any kind in
        connection with your use, or inability to use, the website, any website
        linked to it, and any services we provide, including from all express
        and implied warrantied and representations. We will not be responsible
        for any errors or misstatements or be liable, whether in contract, tort
        (including negligence) or otherwise, for any loss or damage however
        caused (including but not limited to indirect, consequential or special
        loss or damage, or personal injury, pain and suffering, emotional
        distress, loss of profits, loss of data, loss of anticipated savings or
        loss of opportunity), even if foreseeable.
      </p>
      <p>
        The limitation of liability set out above does not apply to liability
        resulting from our gross negligence or wilful misconduct.
      </p>
      <p>
        If you are a consumer using our website for non-business activities then
        nothing in these Terms is intended to abrogate any rights you might have
        under the Consumer Guarantees Act 1993 (if any).
      </p>

      <h3 className="font-bold">4. Users</h3>
      <p>
        The Kit Me tool is for New Zealand based household use only. Our funding
        is limited, and we only have the budget currently for household access
        for Kiwis. If you would like access to the tool and are part of a
        business, organisation, or in another country - please contact us for
        more information hello@kitme.co.nz we would love to work with you!
      </p>
      <p>
        We reserve the right to limit, suspend or terminate your access to the
        website without notice and/or claim costs incurred by your use of the
        tool. This includes if you are using the kit me tool for non-household
        use, out of New Zealand use, or for any other unacceptable purpose. On
        termination of access, you must immediately cease using the Website and
        must not attempt to gain further access.
      </p>
      <p>
        You may use this website only for lawful purposes. You agree not to use
        the website a) in a way that violates any local, or other applicable law
        or regulation or b) to engage in any other conduct that restricts or
        inhibits anyone’s use or enjoyment of the website, or which, as
        determined by us, may harm us or users of the website, or expose them to
        liability. Any inappropriate or illegal use of, or interaction with the
        website or its components will result in loss of access to the tool. We
        will cooperate fully with authorities in the event of unlawful activity
        affecting this site.
      </p>
      <p>
        You will create a login and password to use Kit Me. To keep your
        personal information secure, you must keep your login and password
        secure and: a) not permit any other person to use your login and
        password, including not disclosing or providing it to any other person;
        b) notify us if you become aware of any disclosure or unauthorised use
        of your login or password, by sending an email to hello@kitme.co.nz. c)
        regularly change your password, d) use Kit Me only on devices you trust,
        which are up to date and have all pending security updates applied.
      </p>
      <p>
        Please provide true, current and complete information in your dealings
        with us, and promptly update that information as required so that the
        information remains true, current and complete. This will enable us to
        provide accurate suggestions for your emergency supplies. If we need to
        contact you, we may do so by email. Please keep your email address up to
        date.
      </p>

      <h3 className="font-bold">5. Intellectual property</h3>
      <p>
        You acknowledge that all intellectual property rights (including all
        copyright, trade mark rights and other intellectual property rights in
        any text, graphic, logo, icon, image, audio clip, digital download,
        data, software, look and feel and other content or material) in or
        related to the Kit Me tool, and all underlying systems, are owned by
        Inclement, our partners or our licensors. You may not, without
        Inclement’s prior written permission, copy, distribute, reproduce,
        display or publish any, or any part of any, content of the Kit Me tool
        or any work relating to any services we provide, including any trade
        mark that appears on the tool, for any purpose whatsoever without our
        prior written approval. You agree to indemnify us against any claims,
        costs, damages or losses incurred by us should you fail to comply with
        this clause.
      </p>
      <p>
        You are welcome to create a hyperlink from another website to this one.
        However, the link must not be presented on your website in any context
        that implies that this website has an association with your site, or
        endorses your site or product, or is otherwise a breach of the Fair
        Trading Act 1986. If you would like to link to us in this manner, email
        your request to hello@kitme.co.nz - We reserve the right to limit who
        links to our website. All intellectual property rights in the Kit Me
        logo are owned by Inclement, including all underlying trade marks, and
        accordingly the Kit Me logo may not be used without Inclement’s
        permission.
      </p>

      <h3 className="font-bold">6. Third party Copyright</h3>
      <p>
        The Kit Me tool may contain links to third party websites, applications
        or resources. We are not responsible for the content, policies or
        licensing terms of third party websites, applications or resources.
      </p>
      <p>
        If you use any content where other entities may hold the copyright, you
        should read the terms and conditions applicable to that content and take
        note of any copyright restrictions. Most of this information will be in
        the public domain, and licenced by entities in such a way as to ensure
        that it is disseminated to the public, but you should always check
        before using it.
      </p>
      <p>
        If you have any questions or concerns about third party materials made
        available to you through the Kit Me tool, please get in touch.{" "}
        <a
          className="font-normal text-green-100 hover:underline"
          href={`mailto:${process.env.REACT_APP_CONTACT_EMAIL}`}
        >
          {process.env.REACT_APP_CONTACT_EMAIL}
        </a>
      </p>

      <h3 className="font-bold">7. Continuity of service</h3>
      <p>
        We will make reasonable efforts to continue to provide access to the
        site and your login, because this will help Kiwis get and stay prepared
        for emergencies. However, Inclement makes no warranty or representation,
        express or implied, as to continuity of service. It reserves the right
        to suspend, terminate or otherwise alter access to some or all of the
        site’s services at any time and without notice.
      </p>

      <h3 className="font-bold">8. Advertising</h3>
      <p>
        Any dealings with any advertiser or sponsor appearing on our website are
        solely between you and the advertiser or other third party. We are not
        responsible or liable for any dealings or promotions.
      </p>

      <h3 className="font-bold">9. Indemnity</h3>
      <p>
        You will take all necessary action to defend and indemnify us and our
        directors, employees and agents against all costs, expenses and damages
        incurred in connection with any claim brought by a third party against
        us that arise from a breach by you of any of these terms.
      </p>

      <h3 className="font-bold">10. Information and material</h3>
      <p>
        The content on this site is provided for general information only. You
        should obtain professional or specialist advice before taking or
        refraining from any action on the basis of any information on this site.
      </p>

      <h3 className="font-bold">11. General provisions</h3>
      <p>
        If at any time we do not enforce any of these terms or conditions, we
        will not be construed as having waived that term or condition or our
        rights to later enforce that, or any other, term or condition.
      </p>
      <p>
        Further, if any part or provision of these terms and conditions is
        considered to be invalid, unenforceable or in conflict with the law,
        that part or provision is deemed to be modified by a provision which, as
        far as possible, accomplishes the original purpose of that part or
        provision. The remaining terms and conditions will continue to be
        binding on the parties.
      </p>
      <p>
        Please email us for further information{" "}
        <a
          className="font-normal text-green-100 hover:underline"
          href={`mailto:${process.env.REACT_APP_CONTACT_EMAIL}`}
        >
          {process.env.REACT_APP_CONTACT_EMAIL}
        </a>
      </p>
      <p>Page last updated: 10 March 2022</p>
    </GenericPage>
  );
}
