import { User } from "firebase/auth";
import {
  doc,
  DocumentData,
  Firestore,
  QueryDocumentSnapshot,
  setDoc,
  SnapshotOptions,
  WithFieldValue,
} from "firebase/firestore";
import { useMemo } from "react";
import { useDocument } from "react-firebase-hooks/firestore";
import { useFirebase } from ".";
import { Goals } from "../types";
import {
  FirewardInput,
  FirewardOutput,
  FirewardTypes,
  Household,
  Stored,
  UserData,
} from "../types/fireward";
import { calculateGoals } from "../utils";

export class FirestoreUserInfoConverter<T extends FirewardTypes> {
  toFirestore(userInfo: WithFieldValue<UserData<T>>): DocumentData {
    return userInfo;
  }
  fromFirestore(
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions
  ): UserData<T> {
    const data = snapshot.data(options)!;
    return data as UserData<T>;
  }
}

export const FIRESTORE_USER_INFO_CONVERTER_OUTPUT =
  new FirestoreUserInfoConverter<FirewardOutput>();
export const FIRESTORE_USER_INFO_CONVERTER_INPUT =
  new FirestoreUserInfoConverter<FirewardInput>();

type UseUserDataResult = {
  userData: UserData<FirewardOutput> | null;
  loading: boolean;
  error: any;
  goals: Goals | null;
  updateUserData: (user: Partial<UserData<FirewardInput>>) => Promise<void>;
  updateHousehold: (
    household: Partial<Household<FirewardInput>>
  ) => Promise<void>;
  updateStored: (stored: Partial<Stored<FirewardInput>>) => Promise<void>;
};

export const createUserData = async (
  firestore: Firestore,
  user: User,
  userData:
    | Pick<UserData<FirewardInput>, "joinedAt"> & {
        household: Pick<Household, "size" | "nickname" | "postcode">;
      }
) => {
  const newData: UserData<FirewardInput> = {
    ...userData,
    lastUpdate: userData.joinedAt,
    household: {
      ...userData.household,
      goalDays: null,
      stored: {
        water: 0,
        bleach: 0,
        torch: 0,
        battery: 0,
        measuringCup: false,
        emergencyPlan: false,
      },
    },
  };
  return await setDoc<UserData>(
    doc(firestore, "users", user.uid).withConverter(
      FIRESTORE_USER_INFO_CONVERTER_INPUT
    ),
    newData
  );
};

export const useUserData = (userId: string): UseUserDataResult => {
  const { firestore } = useFirebase();
  const [document, loading, error] = useDocument(
    doc(firestore, "users", userId).withConverter(
      FIRESTORE_USER_INFO_CONVERTER_OUTPUT
    )
  );

  const userData = document?.data() ?? null;

  const setData = async (
    callback: (current: UserData) => UserData
  ): Promise<void> => {
    if (!document?.exists()) throw new Error("Cannot set before retrieved.");
    const newData = callback(document?.data());
    return setDoc<UserData>(
      doc(firestore, "users", userId).withConverter(
        FIRESTORE_USER_INFO_CONVERTER_INPUT
      ),
      newData
    );
  };

  const updateUserData = async (newUserData: Partial<UserData>) => {
    return setData((current) => ({ ...current, ...newUserData }));
  };

  const updateHousehold = async (newHousehold: Partial<Household>) => {
    return setData((current) => ({
      ...current,
      lastUpdate: new Date(),
      household: { ...current.household, ...newHousehold },
    }));
  };

  const updateStored = async (newStored: Partial<Stored>) => {
    return setData((current) => ({
      ...current,
      lastUpdate: new Date(),
      household: {
        ...current.household,
        stored: { ...current.household.stored, ...newStored },
      },
    }));
  };

  const goals = useMemo(
    () => {
      if (userData?.household.size) {
        return calculateGoals(userData?.household.size);
      }
      return null;
    },
    //eslint-disable-next-line react-hooks/exhaustive-deps
    [userData?.household.size]
  );

  return {
    userData,
    loading,
    error,
    goals,
    updateUserData,
    updateHousehold,
    updateStored,
  };
};
