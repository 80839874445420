import { GoalDays } from "./fireward";

export type GoalType = "water" | "bleach" | "torch" | "battery";
export const GOAL_TYPE: GoalType[] = ["water", "bleach", "torch", "battery"];

export type GoalData = {
  id: number;
  type: GoalType;
  name: string;
  unit: string;
  unitPlural?: string;
  icon: string;
  question: string;
};

export const GOAL_DAYS: GoalDays[] = [3, 7, 14];

export type Goals = {
  [key in GoalDays]: { [key in GoalType]: number };
};
