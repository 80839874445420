import { Popover } from "@headlessui/react";
import { signOut } from "firebase/auth";
import { useAuthState } from "react-firebase-hooks/auth";
import { Link, useNavigate } from "react-router-dom";
import { useFirebase } from "../../firebase";
import { KitMeLogo } from "../../images";

export default function Header() {
  const { auth } = useFirebase();
  const [user] = useAuthState(auth);

  const navigate = useNavigate();

  return (
    <Popover className="relative bg-white drop-shadow z-10">
      <div className="max-w-7xl mx-auto px-4 sm:px-6">
        <div className="flex justify-between items-center drop-shadow-md py-6 md:justify-start md:space-x-10 md:px-8">
          <div className="flex justify-start lg:w-0 lg:flex-1">
            <Link to="/" tabIndex={0}>
              <img className="h-10 w-auto" src={KitMeLogo} alt="Kit Me" />
            </Link>
          </div>
          <div className="md:flex items-center justify-end md:flex-1 min-w-0">
            {(!user && (
              <>
                <Link
                  className="whitespace-nowrap text-base font-medium text-grey hover:accent-green hover:underline focus:underline"
                  tabIndex={0}
                  to="/login"
                >
                  Sign in
                </Link>
                <Link
                  className="ml-8 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border-2 border-transparent rounded-md shadow-sm text-base font-medium text-dark border-accent-green hover:bg-accent-green hover:text-white"
                  tabIndex={0}
                  to="/signup"
                >
                  Sign up
                </Link>
              </>
            )) || (
              <>
                <Link
                  className="whitespace-nowrap text-base font-medium text-grey hover:accent-green hover:underline focus:underline"
                  to="/dashboard"
                  tabIndex={0}
                >
                  Dashboard
                </Link>
                <button
                  onClick={async () => {
                    try {
                      await signOut(auth);
                    } finally {
                      navigate("/");
                    }
                  }}
                  tabIndex={0}
                  className="ml-8 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border-2 border-transparent rounded-md shadow-sm text-base font-medium text-dark border-accent-green hover:bg-accent-green hover:text-white"
                >
                  Sign out
                </button>
              </>
            )}
          </div>
        </div>
      </div>
    </Popover>
  );
}
