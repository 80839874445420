import { useNavigate } from "react-router-dom";
import { useEffect, useRef } from "react";
import Alert from "../alert";
import classNames from "classnames";

import useForm, { ValidationErrors } from "../form";
import { User } from "firebase/auth";
import { useUserData } from "../../firebase/useUserData";

type FormState = {
  householdNickname: string;
  householdSize: number;
  privacy: boolean;
  postcode: string;
};

export default function ChangeHouseholdForm({ user }: { user: User }) {
  const navigate = useNavigate();
  const { userData, updateHousehold } = useUserData(user.uid);
  const errorRef = useRef<HTMLDivElement>(null);

  const {
    error,
    disabled,
    formState,
    updateFormState,
    validationErrors,
    validateAndSubmit,
  } = useForm<FormState>({
    defaultState: {
      householdNickname: userData?.household.nickname ?? "",
      householdSize: userData?.household.size ?? 1,
      postcode: userData?.household.postcode ?? "",
      privacy: false,
    },
    onValidate: () => {
      let validationErrors = new ValidationErrors<FormState>();
      if (!formState.postcode) {
        validationErrors.addError("postcode", "A postcode must be provided.");
      } else if (!/^\d{4}$/.test(formState.postcode)) {
        validationErrors.addError("postcode", "The postcode is not valid.");
      }

      if (formState.householdNickname.length === 0) {
        validationErrors.addError(
          "householdNickname",
          "The household nickname is invalid."
        );
      }
      return validationErrors;
    },
    onSubmit: async () => {
      if (!userData || !updateHousehold)
        throw new Error("An error occurred saving data.");

      await updateHousehold({
        postcode: formState.postcode,
        size: formState.householdSize,
        nickname: formState.householdNickname.trim(),
      });
      await navigate("/dashboard");
    },
    onError: () => {
      errorRef.current?.scrollIntoView();
    },
  });

  useEffect(
    () => {
      updateFormState({
        householdNickname: userData?.household.nickname ?? "",
        householdSize: userData?.household.size ?? 1,
        postcode: userData?.household.postcode ?? "",
      });
    },
    //eslint-disable-next-line react-hooks/exhaustive-deps
    [
      userData?.household.nickname,
      userData?.household.postcode,
      userData?.household.size,
    ]
  );

  return (
    <div>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          validateAndSubmit();
        }}
        noValidate={true}
        className="space-y-8 divide-y divide-light-grey pb-5"
      >
        <div className="space-y-8 divide-y divide-light-grey">
          <div className="max-w-2xl mx-auto text-center pt-8 space-y-4 sm:pt-10">
            <h1 className="font-extrabold text-2xl sm:text-3xl text-dark">
              Update your <span className="text-bright-green">details</span>
            </h1>
          </div>

          <div ref={errorRef} className="pt-6">
            {error !== "" && (
              <div className="mb-5">
                <Alert type="error">{error}</Alert>
              </div>
            )}
            {validationErrors.hasErrors() && (
              <div className="mb-5">
                <Alert type="error">
                  <ul className="ml-4 list-disc">
                    {Object.values(validationErrors.errors ?? {}).map((x) => (
                      <li key={x.error}>{x.error}</li>
                    ))}
                  </ul>
                </Alert>
              </div>
            )}
            <div className="mt-2 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-4">
              <div className="sm:col-span-3">
                <label
                  htmlFor="postcode"
                  className="block text-xs font-medium text-content-grey"
                >
                  Postcode
                </label>
                <div className="mt-1 flex items-center">
                  <input
                    type="number"
                    name="postcode"
                    id="postcode"
                    autoComplete="postal-code"
                    value={formState.postcode}
                    disabled={disabled}
                    onChange={(e) => {
                      validationErrors.clearErrors("postcode");
                      updateFormState({
                        postcode: e.target.value.trim(),
                      });
                    }}
                    className={classNames(
                      "shadow-sm focus:ring-accent-green focus:border-accent-green block sm:text-sm border-light-grey rounded-md flex-initial w-1/2 disabled:bg-light-grey",
                      {
                        "border-red": validationErrors.hasErrors("postcode"),
                      }
                    )}
                  />
                  <a
                    href="https://www.nzpost.co.nz/tools/address-postcode-finder"
                    target="_blank"
                    rel="noreferrer"
                    tabIndex={0}
                    className="text-xs font-normal text-green-100 hover:underline focus:underline ml-5"
                  >
                    Find your postcode
                  </a>
                </div>
              </div>
            </div>
            <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-4">
              <div className="sm:col-span-1">
                <label
                  htmlFor="people"
                  className="block text-xs font-medium text-content-grey"
                >
                  Number of people
                </label>
                <div className="mt-1">
                  <select
                    id="people"
                    name="people"
                    className="shadow-sm focus:ring-accent-green focus:border-accent-green block w-full sm:text-sm border-light-grey rounded-md disabled:bg-light-grey"
                    disabled={disabled}
                    value={formState.householdSize}
                    onChange={(e) => {
                      updateFormState({
                        householdSize: parseInt(e.target.value),
                      });
                    }}
                  >
                    {Array.from({ length: 15 }, (x, i) => {
                      const size = i + 1;
                      return (
                        <option key={i} value={size}>
                          {size}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>

              <div className="sm:col-span-3">
                <label
                  htmlFor="household"
                  className="block text-xs font-medium text-content-grey"
                >
                  Household nickname E.g. The Taylor Family
                </label>
                <div className="mt-1">
                  <input
                    type="text"
                    name="household"
                    id="household"
                    autoComplete="household"
                    value={formState.householdNickname}
                    disabled={disabled}
                    onChange={(e) => {
                      validationErrors.clearErrors("householdNickname");
                      updateFormState({
                        householdNickname: e.target.value,
                      });
                    }}
                    className={classNames(
                      "shadow-sm focus:ring-accent-green focus:border-accent-green block sm:text-sm border-light-grey rounded-md disabled:bg-light-grey",
                      {
                        "border-red":
                          validationErrors.hasErrors("householdNickname"),
                      }
                    )}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="pt-5">
            <div className="flex justify-center">
              <button
                disabled={disabled}
                type="submit"
                className="ml-3 inline-flex justify-center py-4 px-8 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-100 hover:bg-active-green"
              >
                Update details
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}
