import GenericPage from "../components/generic-page";

export default function NotFound() {
  return (
    <GenericPage
      title="Not Found"
      subtitle={() => <p>The page your looking for cannot be found.</p>}
    ></GenericPage>
  );
}
