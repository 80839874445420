import { Link, useLocation } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import Alert from "../alert";
import { useFirebase } from "../../firebase";
import { signInWithEmailAndPassword } from "firebase/auth";

export default function LoginForm() {
  const { auth } = useFirebase();

  const { state } = useLocation();
  const resetEmailSent = (state as any)?.reset ?? false;

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const [loginError, setLoginError] = useState("");

  const navigate = useNavigate();
  const [user, loading, authError] = useAuthState(auth);

  const error = loginError ?? authError;

  useEffect(() => {
    if (!loading && user && !submitting && !error) {
      navigate("/dashboard");
    }
  }, [loading, user, navigate, submitting, error]);

  return (
    <form
      onSubmit={async (e) => {
        setSubmitting(true);
        e.preventDefault();
        try {
          await signInWithEmailAndPassword(auth, email, password);
          await navigate("/dashboard");
        } catch (e: any) {
          setLoginError(e.message);
        } finally {
          setSubmitting(false);
        }
      }}
      noValidate={true}
      className="space-y-8 divide-y divide-light-grey pb-5 max-w-lg content mx-auto px-6"
    >
      <div className="space-y-8 divide-y divide-light-grey">
        <div className="max-w-2xl mx-auto text-center pt-8 space-y-4 sm:pt-10">
          <h1 className="font-extrabold text-4xl sm:text-5xl text-dark">
            Sign <span className="text-bright-green">in</span>
          </h1>
          <p className="text-dark">
            Sign in with your login details you created when you started your
            kit.
          </p>
        </div>

        {resetEmailSent && (
          <Alert>
            You will receive an email with instructions on how to reset your
            password.
          </Alert>
        )}

        {error && <Alert type="error">{error}</Alert>}

        <div className="pt-6">
          <div className="mt-2 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-4">
            <div className="sm:col-span-4">
              <label
                htmlFor="email"
                className="block text-xs font-medium text-content-grey"
              >
                Email address
              </label>
              <div className="mt-1">
                <input
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  disabled={submitting}
                  onChange={(e) => setEmail(e.target.value)}
                  className="shadow-sm block w-full sm:text-sm border-light-grey rounded-md focus:ring-accent-green focus:border-accent-green disabled:bg-light-grey"
                />
              </div>
            </div>
          </div>

          <div className="mt-2 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-4">
            <div className="sm:col-span-4">
              <label
                htmlFor="password"
                className="block text-xs font-medium text-content-grey"
              >
                Password
              </label>
              <div className="mt-1">
                <input
                  type="password"
                  name="password"
                  id="password"
                  disabled={submitting}
                  onChange={(e) => setPassword(e.target.value)}
                  className="shadow-sm block w-full sm:text-sm border-light-grey rounded-md focus:ring-accent-green focus:border-accent-green disabled:bg-light-grey"
                />
              </div>
            </div>
            <Link
              className="text-xs font-normal text-green-100 hover:underline sm:col-span-4"
              to="/login/forgotten"
              tabIndex={0}
            >
              Forgot my password
            </Link>
          </div>
        </div>
      </div>

      <div className="pt-5">
        <div className="flex justify-center">
          <button
            type="submit"
            disabled={submitting}
            className="ml-3 inline-flex justify-center py-4 px-8 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-100 hover:bg-active-green"
          >
            Sign in
          </button>
        </div>
      </div>
    </form>
  );
}
