import { User } from "firebase/auth";
import { Link } from "react-router-dom";
import { useUserData } from "../../firebase/useUserData";
import house from "./house.svg";

type ProfileHeaderProps = {
  user: User;
};

export default function ProfileHeader({ user }: ProfileHeaderProps) {
  const { userData, loading } = useUserData(user.uid);

  const householdInfo = (size: number, nickname: string) => {
    if (!loading) {
      const members = `${size} ${size > 1 ? "people" : "person"}`;
      return `${nickname} (${members})`;
    }
  };

  return (
    <div className="rounded-lg bg-white overflow-hidden shadow mx-12">
      <h2 className="sr-only" id="profile-overview-title">
        Profile Overview
      </h2>
      <div className="bg-white p-6">
        <div className="sm:flex sm:items-center sm:justify-between">
          <div className="sm:flex sm:space-x-5">
            <div className="flex-shrink-0 flex">
              <img className="mx-auto my-auto h-10 w-10" src={house} alt="" />
            </div>
            <div className="mt-4 text-center sm:mt-0 sm:pt-1 sm:text-left">
              <p className="text-sm font-medium text-grey">Welcome back</p>
              <p className="text-xl font-bold text-dark sm:text-2xl">
                {userData &&
                  householdInfo(
                    userData.household.size,
                    userData.household.nickname
                  )}
              </p>
              <p className="text-sm font-medium text-grey">{user.email}</p>
            </div>
          </div>
          <div className="mt-5 flex justify-center sm:mt-0">
            <span className="text-grey font-medium px-4 py-2">
              {userData && <>Postcode - {userData.household.postcode}</>}
            </span>
            <Link
              to="/dashboard/details"
              tabIndex={0}
              className="flex justify-center items-center text-center px-4 py-2 border border-mid-grey shadow-sm text-sm font-medium rounded-md text-grey bg-white hover:bg-grey-wash"
            >
              Update details
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
