import { Link, useNavigate } from "react-router-dom";
import { useEffect, useRef } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import Alert from "../alert";
import classNames from "classnames";
import { useFirebase } from "../../firebase";
import {
  createUserWithEmailAndPassword,
  sendEmailVerification,
} from "firebase/auth";
import useForm, { ValidationErrors } from "../form";
import { createUserData } from "../../firebase/useUserData";
import { checkPasswordMeetsRequirements } from "../../utils";

type FormState = {
  email: string;
  password: string;
  passwordConfirm: string;
  householdNickname: string;
  householdSize: number;
  privacy: boolean;
  postcode: string;
};

export default function SignUpForm() {
  const { auth, firestore } = useFirebase();
  const [currentUser, loading] = useAuthState(auth);
  const errorRef = useRef<HTMLDivElement>(null);

  const {
    error,
    disabled,
    formState,
    updateFormState,
    validationErrors,
    validateAndSubmit,
  } = useForm<FormState>({
    defaultState: {
      email: "",
      password: "",
      passwordConfirm: "",
      householdNickname: "",
      postcode: "",
      privacy: false,
      householdSize: 1,
    },
    onValidate: () => {
      const errors = new ValidationErrors<FormState>();
      if (
        !formState.email ||
        formState.email.length < 3 ||
        !formState.email.includes("@")
      ) {
        errors.addError("email", "Email is not valid.");
      }

      if (!checkPasswordMeetsRequirements(formState.password)) {
        errors.addError("password", "Password is not valid.");
      }

      if (
        (formState.password.length !== 0 ||
          formState.passwordConfirm.length !== 0) &&
        formState.password !== formState.passwordConfirm
      ) {
        errors.addError(
          ["password", "passwordConfirm"],
          "Passwords don't match"
        );
      }

      if (!formState.postcode) {
        errors.addError("postcode", "A postcode must be provided.");
      } else if (!/^\d{4}$/.test(formState.postcode)) {
        errors.addError("postcode", "The postcode is not valid.");
      }

      if (formState.householdNickname.length === 0) {
        errors.addError(
          "householdNickname",
          "The household nickname is invalid."
        );
      }

      if (!formState?.privacy) {
        errors.addError("privacy", "The privacy policy must be agreed to.");
      }
      return errors;
    },
    onSubmit: async () => {
      const userCredentials = await createUserWithEmailAndPassword(
        auth,
        formState.email,
        formState.password
      );

      await sendEmailVerification(userCredentials.user);

      await createUserData(firestore, userCredentials.user, {
        household: {
          size: formState.householdSize,
          nickname: formState.householdNickname,
          postcode: formState.postcode,
        },
        joinedAt: new Date(),
      });
      await navigate("/dashboard");
    },
    onError: () => {
      errorRef.current?.scrollIntoView();
    },
  });

  const navigate = useNavigate();

  useEffect(() => {
    // If there users is signed in and we aren't submitting the form.
    if (!loading && !disabled && !error && currentUser) navigate("/dashboard");
  }, [navigate, currentUser, loading, disabled, error]);

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        validateAndSubmit();
      }}
      noValidate={true}
      className="space-y-8 divide-y divide-light-grey pb-5 max-w-lg content mx-auto px-6"
    >
      <div className="space-y-8 divide-y divide-light-grey">
        <div className="max-w-2xl mx-auto text-center pt-8 space-y-4 sm:pt-10">
          <h1 className="font-extrabold text-4xl sm:text-5xl text-dark">
            Start your <span className="text-bright-green">Kit</span>
          </h1>
          <p className="text-dark">
            Tell us about yourself to start your household’s emergency readiness
            journey.
          </p>
        </div>

        <div className="pt-6" ref={errorRef}>
          {error !== "" && (
            <div className="mb-5">
              <Alert type="error">{error}</Alert>
            </div>
          )}
          {validationErrors.hasErrors() && (
            <div className="mb-5">
              <Alert type="error">
                <ul className="ml-4 list-disc">
                  {Object.values(validationErrors.errors ?? {}).map((x) => (
                    <li key={x.error}>{x.error}</li>
                  ))}
                </ul>
              </Alert>
            </div>
          )}
          <div className="mt-2 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-4">
            <div className="sm:col-span-4">
              <label
                htmlFor="email"
                className="block text-xs font-medium text-content-grey"
              >
                Email address
              </label>
              <div className="mt-1">
                <input
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  value={formState.email}
                  disabled={disabled}
                  onChange={(e) => {
                    validationErrors.clearErrors("email");
                    updateFormState({ email: e.target.value.trim() });
                  }}
                  className={classNames(
                    "shadow-sm focus:ring-accent-green focus:border-accent-green block w-full sm:text-sm border-light-grey rounded-md disabled:bg-light-grey",
                    {
                      "border-red": validationErrors.hasErrors("email"),
                    }
                  )}
                />
              </div>
            </div>

            <div className="sm:col-span-3">
              <label
                htmlFor="postcode"
                className="block text-xs font-medium text-content-grey"
              >
                Postcode
              </label>
              <div className="mt-1 flex items-center">
                <input
                  type="number"
                  name="postcode"
                  id="postcode"
                  autoComplete="postal-code"
                  value={formState.postcode}
                  disabled={disabled}
                  onChange={(e) => {
                    validationErrors.clearErrors("postcode");
                    updateFormState({
                      postcode: e.target.value.trim(),
                    });
                  }}
                  className={classNames(
                    "shadow-sm focus:ring-accent-green focus:border-accent-green block sm:text-sm border-light-grey rounded-md flex-initial w-1/2 disabled:bg-light-grey",
                    {
                      "border-red": validationErrors.hasErrors("postcode"),
                    }
                  )}
                />
                <a
                  href="https://www.nzpost.co.nz/tools/address-postcode-finder"
                  target="_blank"
                  tabIndex={0}
                  rel="noreferrer"
                  className="text-xs font-normal text-green-100 hover:underline focus:underline ml-5"
                >
                  Find your postcode
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="pt-8">
          <h2 className="text-lg">Your household</h2>
          <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-4">
            <div className="sm:col-span-1">
              <label
                htmlFor="people"
                className="block text-xs font-medium text-content-grey"
              >
                Number of people
              </label>
              <div className="mt-1">
                <select
                  id="people"
                  name="people"
                  className="shadow-sm focus:ring-accent-green focus:border-accent-green block w-full sm:text-sm border-light-grey rounded-md disabled:bg-light-grey"
                  disabled={disabled}
                  onChange={(e) => {
                    updateFormState({
                      householdSize: parseInt(e.target.value),
                    });
                  }}
                >
                  {Array.from({ length: 15 }, (x, i) => (
                    <option key={i} value={i + 1}>
                      {i + 1}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div className="sm:col-span-3">
              <label
                htmlFor="household"
                className="block text-xs font-medium text-content-grey"
              >
                Household nickname E.g. The Taylor Family
              </label>
              <div className="mt-1">
                <input
                  type="text"
                  name="household"
                  id="household"
                  autoComplete="household"
                  value={formState.householdNickname}
                  disabled={disabled}
                  onChange={(e) => {
                    validationErrors.clearErrors("householdNickname");
                    updateFormState({
                      householdNickname: e.target.value,
                    });
                  }}
                  className={classNames(
                    "shadow-sm focus:ring-accent-green focus:border-accent-green block sm:text-sm border-light-grey rounded-md disabled:bg-light-grey",
                    {
                      "border-red":
                        validationErrors.hasErrors("householdNickname"),
                    }
                  )}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="pt-8">
          <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-4">
            <div className="sm:col-span-4">
              <label
                htmlFor="password"
                className="block text-xs font-medium text-content-grey"
              >
                Password
              </label>
              <div className="mt-1">
                <input
                  type="password"
                  name="password"
                  id="password"
                  value={formState.password}
                  disabled={disabled}
                  onChange={(e) => {
                    validationErrors.clearErrors("password");
                    updateFormState({ password: e.target.value });
                  }}
                  className={classNames(
                    "shadow-sm focus:ring-accent-green focus:border-accent-green block w-full sm:text-sm border-light-grey rounded-md disabled:bg-light-grey",
                    {
                      "border-red": validationErrors.hasErrors("password"),
                    }
                  )}
                />
              </div>
            </div>
            <div className="sm:col-span-4">
              <label
                htmlFor="password-confirm"
                className="block text-xs font-medium text-content-grey"
              >
                Confirm password
              </label>
              <div className="mt-1">
                <input
                  type="password"
                  name="password-confirm"
                  id="password-confirm"
                  value={formState.passwordConfirm}
                  disabled={disabled}
                  onChange={(e) => {
                    validationErrors.clearErrors("password");
                    updateFormState({
                      passwordConfirm: e.target.value,
                    });
                  }}
                  className={classNames(
                    "shadow-sm focus:ring-accent-green focus:border-accent-green block w-full sm:text-sm border-light-grey rounded-md disabled:bg-light-grey",
                    {
                      "border-red": validationErrors.hasErrors("password"),
                    }
                  )}
                />
              </div>
            </div>
            <div className="sm:col-span-4">
              <div className="text-content-grey text-sm">
                <span className="font-bold">Your password must:</span>
                <ul className="ml-4 list-disc">
                  <li>contain numbers;</li>
                  <li>contain uppercase letters;</li>
                  <li>be greater than 8 characters</li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="">
          <div className="mt-6">
            <div className="mt-4 space-y-4">
              <div className="relative flex items-start">
                <div className="flex items-center h-5">
                  <input
                    id="privacy"
                    name="privacy"
                    type="checkbox"
                    checked={formState.privacy}
                    disabled={disabled}
                    className={classNames(
                      "focus:border-accent-green h-4 w-4 text-accent-green border-light-grey rounded disabled:bg-light-grey",
                      {
                        "border-red": validationErrors.hasErrors("privacy"),
                      }
                    )}
                    onChange={(e) => {
                      validationErrors.clearErrors("privacy");
                      updateFormState({ privacy: e.target.checked });
                    }}
                  />
                </div>
                <div className="ml-3 text-sm">
                  <label
                    htmlFor="privacy"
                    className="font-medium text-content-grey"
                  >
                    Privacy policy
                  </label>
                  <p className="text-content-grey">
                    I agree to the{" "}
                    <Link
                      to="/privacy"
                      tabIndex={0}
                      target="_blank"
                      rel="noreferrer"
                      className="text-xs font-normal text-green-100 underline sm:col-span-4"
                    >
                      terms in the privacy statement
                    </Link>{" "}
                    and{" "}
                    <Link
                      to="/terms"
                      tabIndex={0}
                      target="_blank"
                      rel="noreferrer"
                      className="text-xs font-normal text-green-100 underline sm:col-span-4"
                    >
                      website conditions of use.
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="pt-5">
        <div className="flex justify-center">
          <button
            disabled={disabled}
            type="submit"
            className="ml-3 inline-flex justify-center py-4 px-8 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-100 hover:bg-active-green"
          >
            Start my kit
          </button>
        </div>
      </div>
    </form>
  );
}
