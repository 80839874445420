import { initializeApp, FirebaseApp } from "firebase/app";
import { Auth, getAuth } from "firebase/auth";
import {
  enableIndexedDbPersistence,
  Firestore,
  getFirestore,
} from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";

import { createContext, ReactNode, useContext, useMemo } from "react";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

/* Fix for analytics cookie errors.
 * See https://github.com/firebase/firebase-js-sdk/issues/4123
 */
function analyticsFix(_window: any) {
  _window.dataLayer = _window.dataLayer || [];
  _window.gtag = function () {
    _window.dataLayer.push(arguments);
  };

  _window.gtag("config", firebaseConfig.measurementId, {
    cookie_domain: _window.location.hostname,
    cookie_flags: "SameSite=None;Secure",
  });
}

export type FirebaseResult = {
  app: FirebaseApp;
  auth: Auth;
  firestore: Firestore;
};

export const FirebaseContext = createContext<FirebaseResult | null>(null);

function useFirebaseInstance(): FirebaseResult {
  return useMemo(() => {
    analyticsFix(window as any);
    const app = initializeApp(firebaseConfig);
    const auth = getAuth(app);
    const firestore = getFirestore(app);
    const analytics = getAnalytics(app);

    try {
      enableIndexedDbPersistence(firestore);
    } catch (e: any) {}
    return { app, analytics, auth, firestore };
  }, []);
}

export function FirebaseContextProvider({ children }: { children: ReactNode }) {
  return (
    <FirebaseContext.Provider value={useFirebaseInstance()}>
      {children}
    </FirebaseContext.Provider>
  );
}

export const useFirebase = () => {
  const firebaseContext = useContext(FirebaseContext);
  if (firebaseContext === undefined || firebaseContext === null) {
    throw new Error("useFirebase not in FirebaseContext.Provider");
  }
  return firebaseContext;
};
