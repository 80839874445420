import { Link } from "react-router-dom";
import BrandPanel from "../components/brand-panel";
import CTA from "../components/cta";
import Features from "../components/features";
import { Trees } from "../images";

export default function Home() {
  const css = { backgroundImage: `url(${Trees})` };
  return (
    <div className="space-y-24 pb-20">
      <div>
        <div
          className="bg-[#D7F0F3] aspect-w-2 aspect-h-1 bg-no-repeat bg-contain bg-bottom pt-72 sm:pt-26 md:pt-36 lg:pt-20"
          style={css}
        >
          <div className="absolute inset-x-0 pt-6 sm:pt-12 md:pt-8 lg:pt-24 px-6">
            <CTA
              Link={(props) => (
                <Link {...props} to="/signup">
                  Start my kit
                </Link>
              )}
              title={
                <>
                  The readiness tool for{" "}
                  <span className="text-accent-green">Kiwis</span>
                </>
              }
            >
              Start your emergency readiness journey with Kit Me. We help Kiwis
              prepare their households and empower communities to build
              resilience. All through the power of Kiwi ingenuity and
              resourcefulness.
            </CTA>
          </div>
        </div>
        <div className="bg-[#A5D2B5] -mt-24 md:-mt-32 lg:-mt-48 xl:-mt-56 py-8">
          <Features />
        </div>
      </div>
      <BrandPanel />
      <div className="max-w-4xl mx-auto">
        <CTA
          titleSize={2}
          title={
            <>
              A <span className="text-accent-green">brighter</span> future -
              with your help
            </>
          }
          Link={(props) => (
            <Link {...props} to="/signup">
              Find out more
            </Link>
          )}
        >
          We want Kit Me to be a fully fledged, one-of-a-kind emergency
          readiness tool guiding Kiwis through tough decisions - not just on
          water and light, but food, health supplies and other items you need in
          an emergency. We then crunch the numbers to help Emergency Management
          groups build community resilience ahead of an emergency.
        </CTA>
      </div>
    </div>
  );
}
