import { ReactElement } from "react";
import WCCDEMLogo from "./wccdemLogo.png";
import MediaSuiteLogo from "./mediaSuiteLogo.png";
import inclementLogo from "./inclementLogo.png";
import { useLocation } from 'react-router-dom';

export default function Footer(props: { children: ReactElement[] }) {
  
  // An attribution will be set if there are tree svg's from www.freepik.com redered on a page
  // Currently the home page '/' is the only location
  const location = useLocation().pathname
  const hasTreeAttribution = location === '/' ? true : false
  
  return (
    <footer className="bg-white text-content-grey">
      <div className="max-w-7xl mx-auto py-12 px-4 overflow-hidden sm:px-6 lg:px-8">
        <nav
          className="-my-2 flex flex-wrap justify-center flex-col mx-4 sm:flex-row sm:-mx-5"
          aria-label="Footer"
        >
          {props.children.map((item, i) => (
            <div key={i} className="px-5 py-2">
              {item}
            </div>
          ))}
        </nav>
        <div className="grid sm:grid-flow-col auto-cols-max justify-evenly mt-12 gap-4">
          <a
            className="mx-auto sm:mx-0"
            href="https://westcoastemergency.govt.nz/"
            target="_blank"
            rel="noreferrer"
          >
            <img
              className="h-12" 
              src={WCCDEMLogo}
              alt="West Coast Civil Defence Emergency Management Group"
            />
          </a>
          <a
            className="mx-auto sm:mx-0 md:pl-12"
            href="https://www.kitme.co.nz"
            target="_blank"
            rel="noreferrer"
            >
            <img 
              className="h-12" 
              src={inclementLogo} 
              alt="Inclement" 
            />
          </a>
          <a
            className="mx-auto sm:mx-0"
            href="https://www.mediasuite.co.nz/"
            target="_blank"
            rel="noreferrer"
          >
            <img 
              className="h-12"  
              src={MediaSuiteLogo} 
              alt="Media Suite" 
            />
          </a>
        </div>
        <div className="mt-12 justify-center text-base text-grey font-bold flex space-x-8">
          <a href={`mailto:${process.env.REACT_APP_CONTACT_EMAIL}`}>
            {process.env.REACT_APP_CONTACT_EMAIL}
          </a>
        </div>
        <p className="mt-8 text-center text-base text-grey">
          &copy; 2022 Kit Me All rights reserved.
        </p>
        { hasTreeAttribution && 
        <div className="pt-4 px-4">
          <a
            href="https://www.freepik.com/vectors/grassland"
            className="no-underline"
          >
            Grassland vector created by studiogstock - www.freepik.com
          </a>
        </div>
        }
      </div>
    </footer>
  );
}
