import ProgressBar, { ProgressBarTheme } from "../progress-bar";
import { BatteryIcon, BleachIcon, TorchIcon, WaterIcon } from "../../images";
import { humaniseDays } from "../../utils";
import { GoalData, Goals, GoalType, GOAL_TYPE } from "../../types";
import GoalProgressCard from "../goal-progress-card";
import BleachGoalProgressCard from "../goal-progress-card/bleach";
import { Fragment, useEffect, useState } from "react";
import Alert from "../alert";
import {
  FirewardInput,
  FirewardOutput,
  GoalDays,
  Household,
  Stored,
} from "../../types/fireward";
import { useDebouncedCallback } from "use-debounce";

const GOAL_DATA: GoalData[] = [
  {
    id: 1,
    type: "water",
    name: "Stored water",
    unit: "L",
    icon: WaterIcon,
    question: "How many litres of stored water do you have?",
  },
  {
    id: 2,
    type: "bleach",
    name: "Bleach",
    unit: "L",
    icon: BleachIcon,
    question: "How many litres of bleach do you have?",
  },
  {
    id: 3,
    type: "torch",
    name: "Torches",
    unit: " Torch",
    unitPlural: " Torches",
    icon: TorchIcon,
    question: "How many torches do you have?",
  },
  {
    id: 4,
    type: "battery",
    name: "Spare battery sets",
    unit: " Set",
    unitPlural: " Sets",
    icon: BatteryIcon,
    question: "How many sets of spare batteries do you have?",
  },
];

export default function GoalProgress({
  goals,
  household,
  updateStored,
}: {
  goals: Goals;
  household: Household<FirewardOutput>;
  updateStored: (stored: Partial<Stored<FirewardInput>>) => Promise<void>;
}) {
  if (!household.goalDays) throw new Error("Goal not set");
  const goalDays: GoalDays = household.goalDays;

  const [stored, setStored] = useState(household.stored);

  useEffect(() => {
    setStored(household.stored);
  }, [household.stored]);

  const debouncedUpdateStored: (stored: Partial<Stored>) => void =
    useDebouncedCallback((stored) => {
      updateStored(stored);
    }, 1000);

  useEffect(
    () => {
      if (stored !== household.stored) debouncedUpdateStored(stored);
    },
    //eslint-disable-next-line react-hooks/exhaustive-deps
    [stored, debouncedUpdateStored]
  );

  const allGoalsMet =
    GOAL_TYPE.every(
      (type) =>
        household.goalDays && goals[household.goalDays][type] <= stored[type]
    ) && stored?.measuringCup;

  function waterGoalProgress(days: GoalDays, stored: number) {
    return {
      title: humaniseDays(days),
      fill: stored === 0 ? 0 : (stored / goals[days]["water"]) * 100,
    };
  }

  function goalProgressForType(type: GoalType) {
    switch (type) {
      case "water":
        var progress = [
          waterGoalProgress(3, stored[type]),
          waterGoalProgress(7, stored[type]),
          waterGoalProgress(14, stored[type]),
        ];

        switch (household.goalDays) {
          case 3:
            return progress.slice(0, 1);
          case 7:
            return progress.slice(0, 2);
        }
        return progress;
      case "bleach":
        var bleachTarget = goals[goalDays][type];
        return [
          {
            title: `${bleachTarget} ${bleachTarget > 1 ? "Litres" : "Liter"}`,
            fill: (stored[type] / bleachTarget) * 100,
          },
          {
            title: `Measuring Utensil`,
            fill: stored.measuringCup ? 100 : 0,
          },
        ];
      case "torch":
      case "battery":
        const target = goals[goalDays][type];
        let title = `${target} `;
        if (type === "torch") {
          title += `Torch${target > 1 ? "es" : ""}`;
        } else {
          title += `Set${target > 1 ? "s" : ""} of batteries`;
        }
        return [
          {
            title: title,
            fill: (stored[type] / target) * 100,
          },
        ];
    }
  }

  const updateValueForType = (type: GoalType, value: number) => {
    setStored((prev) => ({
      ...prev,
      [type]: value,
    }));
  };

  return (
    <>
      {allGoalsMet && (
        <Alert
          type="check"
          title={`${humaniseDays(household.goalDays, false)} target met`}
        >
          {household.goalDays === 3 &&
            "Great work, your household can now start thinking the 1 week goal."}
          {household.goalDays === 7 &&
            "Great work, your household can now start thinking the 2 week goal."}
          {household.goalDays === 14 &&
            "Great work, your household can now start thinking about long-term planning."}
        </Alert>
      )}
      <div className="grid grid-cols-1 sm:grid-cols-5 gap-y-12 sm:gap-x-12">
        {GOAL_DATA.map((item) => (
          <Fragment key={item.id}>
            <div className="col-span-2 text-content-grey order-1 sm:order-none">
              <h3 className="uppercase">{item.name}</h3>
              <div className="space-y-3 mt-2">
                {goalProgressForType(item.type).map((props) => (
                  <ProgressBar
                    {...props}
                    key={props.title}
                    theme={`bg-${item.type}` as ProgressBarTheme}
                  />
                ))}
              </div>
            </div>
            <div className="col-span-3 sm:order-none">
              {item.type !== "bleach" ? (
                <GoalProgressCard
                  {...item}
                  target={goals[goalDays][item.type]}
                  size={household.size}
                  goalDays={goalDays}
                  value={stored[item.type]}
                  onChange={(value) => updateValueForType(item.type, value)}
                />
              ) : (
                <BleachGoalProgressCard
                  {...item}
                  target={goals[goalDays][item.type]}
                  size={household.size}
                  goalDays={goalDays}
                  value={stored[item.type]}
                  onChange={(value) => updateValueForType(item.type, value)}
                  measuringCupValue={stored.measuringCup}
                  measuringCupChange={(value) =>
                    setStored((prev) => ({ ...prev, measuringCup: value }))
                  }
                />
              )}
            </div>
          </Fragment>
        ))}
      </div>
      <div>
        <h2 className="text-xl">Emergency plan</h2>
        <div className="flex mt-8">
          <input
            id="emergencyPlan"
            name="emergencyPlan"
            type="checkbox"
            className="h-4 w-4 mt-1 text-accent-green border-light-grey rounded focus:ring-accent-green focus:border-accent-green"
            checked={stored.emergencyPlan}
            onChange={(e) =>
              setStored((prev) => ({
                ...prev,
                emergencyPlan: e.target.checked,
              }))
            }
          />
          <div className="ml-2">
            <label
              htmlFor="emergencyPlan"
              className="font-medium text-content-grey focus:ring-accent-green focus:border-accent-green"
            >
              Yes, I have created my emergency plan
            </label>
            <a
              target="_blank"
              rel="noreferrer"
              className="block font-normal text-green-100 hover:underline"
              href="https://getready.govt.nz/prepared/household/make-a-plan/household-plan/"
            >
              Find out more about emergency planning
            </a>
          </div>
        </div>
      </div>
    </>
  );
}
