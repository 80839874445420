import { Route, Routes } from "react-router-dom";
import ForgottenPasswordForm from "../components/forgotten-password-form";
import LoginForm from "../components/login-form";

export default function Login() {
  return (
    <div className="container mx-auto py-5 sm:py-10 space-y-8 sm:space-y-16">
      <Routes>
        <Route path="/" element={<LoginForm />} />
        <Route path="forgotten" element={<ForgottenPasswordForm />} />
      </Routes>
    </div>
  );
}
