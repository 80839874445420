import { sendPasswordResetEmail } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import Alert from "../alert";
import { useFirebase } from "../../firebase";

export default function ForgottenPasswordForm() {
  const { auth } = useFirebase();

  const [email, setEmail] = useState("");
  const [sending, setSending] = useState(false);
  const [error, setError] = useState("");

  const navigate = useNavigate();

  return (
    <form
      onSubmit={async (e) => {
        e.preventDefault();
        setSending(true);
        try {
          await sendPasswordResetEmail(auth, email);
          navigate("/login", { state: { reset: true } });
        } catch (error: any) {
          setError(error.message);
        } finally {
          setSending(false);
        }
      }}
      noValidate={true}
      className="space-y-8 divide-y divide-light-grey pb-5 max-w-lg content mx-auto px-6"
    >
      <div className="space-y-8 divide-y divide-light-grey">
        <div className="max-w-2xl mx-auto text-center pt-8 space-y-4 sm:pt-10">
          <h1 className="font-extrabold text-4xl sm:text-5xl text-dark">
            Reset Password
          </h1>
          <p className="text-dark">
            If a valid email is entered you will receive an email with
            instructions to reset your password.
          </p>
        </div>

        {error !== "" && <Alert type="error">{error}</Alert>}

        <div className="pt-6">
          <div className="mt-2 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-4">
            <div className="sm:col-span-4">
              <label
                htmlFor="email"
                className="block text-xs font-medium text-content-grey"
              >
                Email address
              </label>
              <div className="mt-1">
                <input
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  disabled={sending}
                  onChange={(e) => setEmail(e.target.value)}
                  className="shadow-sm block w-full sm:text-sm border-light-grey rounded-md disabled:bg-light-grey"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="pt-5">
        <div className="flex justify-center">
          <button
            type="submit"
            disabled={sending}
            className="ml-3 inline-flex justify-center py-4 px-8 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-100 hover:bg-active-green"
          >
            Reset password
          </button>
        </div>
      </div>
    </form>
  );
}
