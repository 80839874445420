import { LinkIcon } from "../../images";

export const LINKS = {
  "Steps to Get Ready": "https://getready.govt.nz/prepared/household/",
  "Make a Plan":
    "https://getready.govt.nz/prepared/household/make-a-plan/household-plan/",
  "Storing emergency water":
    "https://getready.govt.nz/prepared/household/supplies/storing-water/",
  "Get ready in Te Reo Māori ":
    "https://getready.govt.nz/mi_NZ",
  "Households with Babies":
    "https://getready.govt.nz/prepared/household/tailor-your-plan/babies-and-young-children/",
  "Advice for Disabled people":
    "https://getready.govt.nz/prepared/advice-for-disabled-people/",
  "Get ready info in accessible formats":
    "https://getready.govt.nz/prepared/advice-for-disabled-people/resources-for-disabled-people/",
};

export default function UsefulResources() {
  return (
    <div id="tips">
      <div className="flex mb-4">
        <img src={LinkIcon} className="h-14 w-14" aria-hidden="true" alt="" />
        <h2 className="text-2xl mt-3 ml-3 font-semibold text-dark">
          Useful resources
        </h2>
      </div>
      <dl className="space-y-5">
        <div className="">
          <h4 className="font-bold mb-4">
            Visit these resources on the Get Ready website:
          </h4>
          <ul>
            {Object.entries(LINKS).map(([key, target]) => (
              <li key={key}>
                <a
                  target="_blank"
                  rel="noreferrer"
                  className="font-normal text-green-100 hover:underline"
                  href={target}
                >
                  {key}
                </a>
              </li>
            ))}
          </ul>
          <h4 className="font-bold mt-6 mb-4">
            For further resources, information or assistance:
          </h4>
          Visit{" "}
          <a
            target="_blank"
            rel="noreferrer"
            className="font-normal text-green-100 hover:underline"
            href="https://getready.govt.nz/"
          >
            getready.govt.nz
          </a>
          , Contact your{" "}
          <a
            target="_blank"
            rel="noreferrer"
            className="font-normal text-green-100 hover:underline"
            href="https://www.civildefence.govt.nz/find-your-civil-defence-group/"
          >
            local Civil Defence Group
          </a>{" "}
          or email us:{" "}
          <a
            className="font-normal text-green-100 hover:underline"
            href={`mailto:${process.env.REACT_APP_CONTACT_EMAIL}`}
          >
            {process.env.REACT_APP_CONTACT_EMAIL}
          </a>
        </div>
      </dl>
    </div>
  );
}
