import GenericPage from "../components/generic-page";

export default function About() {
  return (
    <GenericPage
      title="About us"
      subtitle={() => (
        <p>Kit Me is created by Kiwis to help you be prepared.</p>
      )}
    >
      <>
        <p>
          We work with Emergency Management West Coast, alongside other
          emergency management professionals and scientists, to create positive
          change in the pre-emergency space.
        </p>
        <p>
          Kit Me achieves this by sharing knowledge on emergency preparation
          from the wide variety of trusted agencies and experts out there, and
          putting it in a place where you can track your progression to being
          prepared.
        </p>
        <p>
          This project started as an initiative to help local communities on the
          West Coast, living and working in one of New Zealand’s most high risk
          regions.
        </p>
        <p>
          But what we learned - and continue to learn - is relevant for everyone
          who calls New Zealand home, from Kaitaia to Bluff.
        </p>
        <p>
          It’s all about growing community readiness and resilience through
          shared knowledge.
        </p>
        <p>
          Of course, we are growing too, and the more insights and feedback we
          gather, the more we can develop ways for communities to be prepared.
          In ways that work for you. We look forward to working together with
          you and other communities across New Zealand, and we say thank you to
          everyone who has shared this journey with us so far.
        </p>
        <p>
          If you’d like to know more or share your insights, email{" "}
          <a
            className="font-normal text-green-100 hover:underline"
            href={`mailto:${process.env.REACT_APP_CONTACT_EMAIL}`}
          >
            {process.env.REACT_APP_CONTACT_EMAIL}
          </a>
        </p>
        <p>Welcome to Kit Me.</p>
      </>
    </GenericPage>
  );
}
