import { Route, Routes, useNavigate } from "react-router-dom";

import DashboardMain from "./Main";
import DashboardGoal from "./Goal";
import { useAuthState } from "react-firebase-hooks/auth";
import { useFirebase } from "../../firebase";
import DashboardDetails from "./Details";
import VerifyEmail from "../../components/verify-email";
import { useEffect, useState } from "react";

export default function Dashboard() {
  const navigate = useNavigate();
  const { auth } = useFirebase();
  const [user, authLoading, authError] = useAuthState(auth);
  const [emailVerified, setEmailVerified] = useState(user?.emailVerified);

  useEffect(() => {
    setEmailVerified(user?.emailVerified);

    if (!authLoading && user && !user?.emailVerified) {
      const interval = setInterval(() => {
        user?.reload();
        setEmailVerified(user.emailVerified);
        if (user.emailVerified) clearTimeout(interval);
      }, 2000);
      return () => clearInterval(interval);
    }
  }, [user?.emailVerified, user, authLoading]);

  if (authLoading) {
    return <></>;
  }

  if (!user || authError) {
    navigate("/login");
    return <></>;
  }

  return (
    <div className="container mx-auto py-5 sm:py-10 space-y-8 sm:space-y-16">
      {!emailVerified ? (
        <VerifyEmail user={user} />
      ) : (
        <Routes>
          <Route path="/" element={<DashboardMain user={user} />} />
          <Route path="goal" element={<DashboardGoal user={user} />} />
          <Route path="details" element={<DashboardDetails user={user} />} />
        </Routes>
      )}
    </div>
  );
}
