import Alert from "../alert";
import classNames from "classnames";
import {
  EmailAuthProvider,
  reauthenticateWithCredential,
  updateEmail,
  updatePassword,
  User,
} from "firebase/auth";
import useForm, { ValidationErrors } from "../form";
import { checkPasswordMeetsRequirements } from "../../utils";
import { useRef } from "react";
import { Link } from "react-router-dom";

type FormState = {
  currentPassword: string;
  email: string;
  password: string;
  passwordConfirm: string;
};

export default function ChangeCredentialsForm({ user }: { user?: User }) {
  const errorRef = useRef<HTMLDivElement>(null);

  const {
    error,
    disabled,
    formState,
    updateFormState,
    validationErrors,
    validateAndSubmit,
  } = useForm<FormState>({
    defaultState: {
      currentPassword: "",
      email: "",
      password: "",
      passwordConfirm: "",
    },
    onValidate: () => {
      let errors = new ValidationErrors<FormState>();
      if (formState.currentPassword.length === 0) {
        errors.addError("currentPassword", "Current password is required.");
      }

      if (formState.email.length === 0 && formState.password.length === 0) {
        errors.addError(
          ["email", "password", "passwordConfirm"],
          "New email or password must be provided."
        );
      } else {
        if (
          formState.email.length > 0 &&
          (formState.email.length < 3 || !formState.email.includes("@"))
        ) {
          errors.addError("email", "New email is not valid");
        }

        if (
          formState.password.length !== 0 ||
          formState.passwordConfirm.length !== 0
        ) {
          if (!checkPasswordMeetsRequirements(formState.password)) {
            errors.addError("password", "New password is not valid.");
          }

          if (formState.password !== formState.passwordConfirm) {
            errors.addError(
              ["password", "passwordConfirm"],
              "Passwords don't match."
            );
          }
        }
      }
      return errors;
    },
    onSubmit: async () => {
      if (!user || !user.email)
        throw new Error("An error occurred saving data.");

      await reauthenticateWithCredential(
        user,
        EmailAuthProvider.credential(user.email, formState.currentPassword)
      );
      if (formState.email.length > 0) {
        await updateEmail(user, formState.email);
      }
      if (formState.password.length > 0) {
        await updatePassword(user, formState.password);
      }
    },
    onError: () => {
      errorRef.current?.scrollIntoView();
    },
  });

  return (
    <div>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          validateAndSubmit();
        }}
        noValidate={true}
        className="space-y-8 divide-y divide-light-grey pb-5 max-w-lg content mx-auto px-6"
      >
        <div className="space-y-8 divide-y divide-light-grey">
          <div className="max-w-2xl mx-auto text-center pt-8 space-y-4 sm:pt-10">
            <h1 className="font-extrabold text-2xl sm:text-3xl text-dark">
              Update your{" "}
              <span className="text-bright-green">contact details</span>
            </h1>
          </div>

          <div ref={errorRef} className="pt-6">
            {error !== "" && (
              <div className="mb-5">
                <Alert type="error">{error}</Alert>
              </div>
            )}
            {validationErrors.hasErrors() && (
              <div className="mb-5">
                <Alert type="error">
                  <ul className="ml-4 list-disc">
                    {Object.values(validationErrors.errors ?? {}).map((x) => (
                      <li key={x.error}>{x.error}</li>
                    ))}
                  </ul>
                </Alert>
              </div>
            )}
            <div className="mt-2 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-4">
              <div className="sm:col-span-4">
                <label
                  htmlFor="email"
                  className="block text-xs font-medium text-content-grey"
                >
                  Current email address
                </label>
                <div className="mt-1">
                  <input
                    id="currentEmail"
                    name="currentEmail"
                    type="email"
                    autoComplete="email"
                    value={user?.email ?? ""}
                    disabled={true}
                    className="shadow-sm bg-light-grey focus:ring-accent-green focus:border-accent-green block w-full sm:text-sm border-light-grey rounded-md disabled:bg-light-grey"
                  />
                </div>
              </div>
              <div className="sm:col-span-4">
                <label
                  htmlFor="password"
                  className="block text-xs font-medium text-content-grey"
                >
                  Current password
                </label>
                <div className="mt-1">
                  <input
                    type="password"
                    name="currentPassword"
                    id="currentPassword"
                    disabled={disabled}
                    value={formState.currentPassword}
                    onChange={(e) => {
                      validationErrors.clearErrors("currentPassword");
                      updateFormState({
                        currentPassword: e.target.value,
                      });
                    }}
                    className={classNames(
                      "shadow-sm focus:ring-accent-green focus:border-accent-green block w-full sm:text-sm border-light-grey rounded-md disabled:bg-light-grey",
                      {
                        "border-red":
                          validationErrors.hasErrors("currentPassword"),
                      }
                    )}
                  />
                </div>
                <Link
                  className="text-xs font-normal text-green-100 hover:underline sm:col-span-4"
                  to="/login/forgotten"
                  tabIndex={0}
                >
                  Forgot my password
                </Link>
              </div>
            </div>
          </div>
          <div>
            <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-4">
              <div className="sm:col-span-4">
                <label
                  htmlFor="email"
                  className="block text-xs font-medium text-content-grey"
                >
                  New email address
                </label>
                <div className="mt-1">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    autoComplete="email"
                    value={formState.email}
                    disabled={disabled}
                    onChange={(e) => {
                      validationErrors.clearErrors("email");
                      updateFormState({
                        email: e.target.value.trim(),
                      });
                    }}
                    className={classNames(
                      "shadow-sm focus:ring-accent-green focus:border-accent-green block w-full sm:text-sm border-light-grey rounded-md disabled:bg-light-grey",
                      {
                        "border-red": validationErrors.hasErrors("email"),
                      }
                    )}
                  />
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-4">
              <div className="sm:col-span-4">
                <label
                  htmlFor="password"
                  className="block text-xs font-medium text-content-grey"
                >
                  New password
                </label>
                <div className="mt-1">
                  <input
                    type="password"
                    name="password"
                    id="password"
                    disabled={disabled}
                    value={formState.password}
                    onChange={(e) => {
                      validationErrors.clearErrors("password");
                      updateFormState({
                        password: e.target.value,
                      });
                    }}
                    className={classNames(
                      "shadow-sm focus:ring-accent-green focus:border-accent-green block w-full sm:text-sm border-light-grey rounded-md disabled:bg-light-grey",
                      {
                        "border-red": validationErrors.hasErrors("password"),
                      }
                    )}
                  />
                </div>
              </div>
              <div className="sm:col-span-4">
                <label
                  htmlFor="password-confirm"
                  className="block text-xs font-medium text-content-grey"
                >
                  Confirm new password
                </label>
                <div className="mt-1">
                  <input
                    type="password"
                    name="password-confirm"
                    id="password-confirm"
                    disabled={disabled}
                    value={formState.passwordConfirm}
                    onChange={(e) => {
                      validationErrors.clearErrors("password");
                      updateFormState({
                        passwordConfirm: e.target.value,
                      });
                    }}
                    className={classNames(
                      "shadow-sm focus:ring-accent-green focus:border-accent-green block w-full sm:text-sm border-light-grey rounded-md disabled:bg-light-grey",
                      {
                        "border-red": validationErrors.hasErrors("password"),
                      }
                    )}
                  />
                </div>
              </div>
              <div className="sm:col-span-4">
                <p className="text-content-grey text-sm">
                  <span className="font-bold">Your password must:</span>
                  <ul className="ml-4 list-disc">
                    <li>contain numbers;</li>
                    <li>contain uppercase letters;</li>
                    <li>be greater than 8 characters</li>
                  </ul>
                </p>
              </div>
            </div>
          </div>

          <div className="pt-5">
            <div className="flex justify-center">
              <button
                disabled={disabled}
                type="submit"
                className="ml-3 inline-flex justify-center py-4 px-8 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-100 hover:bg-active-green"
              >
                Update contact details
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}
