import "inter-ui/inter.css";
import { BrowserRouter as Router, Link, Route, Routes } from "react-router-dom";
import { About, Dashboard, Home, Login, Privacy, SignUp } from "./pages";
import Footer from "./components/footer";
import Header from "./components/header";
import { FirebaseContextProvider } from "./firebase";
import NotFound from "./pages/NotFound";
import TermsOfUse from "./pages/TermsOfUse";
import ServiceWorkerWrapper from "./ServiceWorkerWrapper";

function App() {
  return (
    <div className="App bg-pale-green">
      <Router>
        <FirebaseContextProvider>
          <Header />
          <ServiceWorkerWrapper />
          <Routes>
            <Route path="*" element={<NotFound />} />
            <Route path="/" element={<Home />} />
            <Route path="/login/*" element={<Login />} />
            <Route path="/signup" element={<SignUp />} />
            <Route path="/dashboard/*" element={<Dashboard />} />
            <Route path="/about" element={<About />} />
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/terms" element={<TermsOfUse />} />
          </Routes>
          <Footer>
            <Link to="/">Home</Link>
            <Link to="/about">About</Link>
            <Link to="/privacy">Privacy Statement</Link>
            <Link to="/terms">Website Terms of Use</Link>
          </Footer>
        </FirebaseContextProvider>
      </Router>
    </div>
  );
}

export default App;
