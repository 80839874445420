import { ReactNode } from "react";
import {
  CheckCircleIcon,
  InformationCircleIcon,
  XCircleIcon,
} from "@heroicons/react/solid";

export type AlertType = "info" | "check" | "error";

export const TYPE_ICON_MAP: { [key in AlertType]: any } = {
  info: InformationCircleIcon,
  check: CheckCircleIcon,
  error: XCircleIcon,
};

export default function Alert({
  title,
  type = "info",
  children,
}: {
  title?: string;
  type?: AlertType;
  children: ReactNode;
}) {
  const Icon = TYPE_ICON_MAP[type];
  return (
    <div className="rounded-md bg-green-100 p-4">
      <div className="flex">
        <div className="flex-shrink-0">
          <Icon className="h-6 w-6 text-white" aria-hidden="true" />
        </div>
        <div className="ml-3">
          {title && (
            <h3 className="text-lg font-medium text-white mb-2">{title}</h3>
          )}
          <div className="text-sm text-white font-medium">{children}</div>
        </div>
      </div>
    </div>
  );
}
